$break-mobile-small: 544px;
$break-mobile: 768px;
$break-tablet: 992px;
$break-desktop-wide: 1200px;

@mixin respond-to($media) {
    @if $media == small-phones {
        @media only screen and (max-width: $break-mobile-small - 1) { @content; }
    }
    @else if $media == phones {
        @media only screen and (max-width: $break-mobile - 1) { @content; }
    }
    @else if $media == tablets {
        @media only screen and (min-width: $break-mobile) and (max-width: $break-tablet - 1) { @content; }
    }
    @else if $media == desktop {
        @media only screen and (min-width: $break-tablet) { @content; }
    }
    @else if $media == desktop-wide {
        @media only screen and (min-width: $break-desktop-wide) { @content; }
    }
    @else if $media == all-mobile {
        @media only screen and (max-width: $break-tablet - 1) { @content; }
    }
    @else if $media == not-phone {
        @media only screen and (min-width: $break-mobile) { @content; }
    }
}
