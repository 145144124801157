@import "~wishlists/components/wishlistGlobal";

.wishlistTile {
	position: absolute;
	top: 0.5rem;
	right: 0.6rem;
	border: none;

	span.fa-stack {
		position: static;
		text-align: center;
		font-size: 1.125rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.fa-heart {
		color: $grey4;
		position: relative;
		font-size: 1.125em;
	}

	i.fa-circle {
		text-shadow: none;
		color: $white;
		font-size: 2.25em;
	}
}
