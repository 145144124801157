.contact-us-appointment {
   padding: 1rem;

  &__heading {
     font-weight: 600; 
       
       @include media-breakpoint-down(xs) {
           font-size: 1.45rem;    
       }
  }

   &__subheading {
       border-bottom: 1px solid $grey4;
       padding-bottom: 10px;

       @include media-breakpoint-down(xs) {
           font-size: 1rem;
       }
   }

   &__title {
       font-weight: 600;
       margin-bottom: 30px;

       @include media-breakpoint-down(xs) {
           font-size: 1rem;
           margin-bottom: 20px;
       }
   }

   &__row-text {
       &:not(:last-child) {
           margin-bottom: 5px;
       }

       span {
           font-weight: 600;
       }

       @include media-breakpoint-down(xs) {
           font-size: 0.8rem;
       }
   }
}
