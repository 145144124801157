@import "~base/utilities/swatch";

@mixin swatch($diameter, $color) {
	margin-right: 4px;
}

$swatch-colors: (
	'beige': #e8e8e8,
	'black': $black,
	'blue': #003e91,
	'brown': #513c2b,
	'green': rgb(179, 208, 64),
	'grey': #838383,
	'navy': #000080,
	'orange': #de1723,
	'pink': #f52887,
	'purple': #800080,
	'red': #b00000,
	'white': $white,
	'yellow': #fffc00,
	'multi': linear-gradient(0deg, #c6007c, #ff0c0e, #ff6201, #ff9000, #ff0, #8bc800, #00a0c4, #005fb1, #6000a0),
	'transparent': linear-gradient(0deg, #f4fafc, #c9e7f0, #f4fafc),
	'gold': #edda74,
	'silver': silver,
	'violett': #914e95
);

@each $color in map-keys($swatch-colors) {
	$colorValue: #{map-get($swatch-colors, $color)};

	.swatch-circle-#{$color} {

		@include circle(2.5em, $colorValue);

		display: block;
		position: relative;

		&.disabled {
			opacity: 0.2;
		}
	}

	.swatch-filter-#{$color} {

		@include circle(1.38em, $colorValue);

		background: $colorValue;
		display: block;
		position: relative;

		&.disabled {
			opacity: 0.2;
		}
	}
}
