.product-set-detail {
	.fa-heart {
		color: var(--color-red);
		font-size: 1.25rem;
	}

	.description-and-detail {
		@include media-breakpoint-only(xs) {
			.collapsible-xs {
				.button-container {
					button {
						padding-left: 0;
						padding-right: 0;
					}
				}
			}
		}
	}

	.set-main {
		.carousel-item.active {
			@include media-breakpoint-down(xs) {
				display: flex;
				justify-content: center;
				align-items: center;
			}
		}

		.carousel-item.active,
		.carousel-item-next,
		.carousel-item-prev {
			justify-content: center;
		}

		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
			gap: 1rem;
		}

		.set-product-name-main {
			.set-product-name {
				padding: 0 0.95rem;

				@include media-breakpoint-down(xs) {
					display: flex;
					justify-content: center;

					h1 {
						font-size: 2rem;
					}
				}
			}

			@include media-breakpoint-down(xs) {
				width: 100%;
				display: flex;
				justify-content: center;
			}
		}
	}

	// styling individual images on product detail page:
	.img-resize-addition {
		width: 20rem;
		height: 20rem;
	}

	// set font styling
	.set-resize-text-sm {
		font-size: 0.8rem;
	}

	.set-resize-text-xs {
		font-size: 0.4rem;
	}

	.set-resize-text-lg {
		font-size: 1.3rem;
	}

	.set-bolden-item {
		font-weight: bold;
	}

	.set-carousel {
		object-fit: contain;
		width: 20rem;
		height: 20rem;
		margin-left: 5rem;

		@include media-breakpoint-down(xs) {
			margin-left: 0;
		}
	}

	.set-carousel-control-sizing {
		top: 35%;
		height: 3.9rem;
		width: 3.5rem;
	}

	.set-carousel-override {
		width: 30rem;
		height: 20rem;
	}

	.carousel-inner {
		@include media-breakpoint-down(xs) {
			width: 22rem;
		}
	}

	.set-main-info {
		display: flex;
		flex-direction: column;
		gap: 2.4rem;
	}

	.set-carousel-div-main {
		display: flex;
		flex-direction: row;
		gap: 4rem;

		@include media-breakpoint-down(xs) {
			width: inherit;
			flex-direction: column;
			align-items: center;
		}
	}

	.set-image-item {
		text-decoration: none !important;
		border-bottom: none;
		display: flex;
		flex-direction: row;
		gap: 2rem;
		width: 10rem;
		height: 10rem;
		justify-content: center;

		@include media-breakpoint-down(xs) {
			align-items: center;
		}
	}

	.set-main-product-info {

		@include media-breakpoint-down(xs) {
			width: inherit;
			display: flex;
			flex-direction: column;
			gap: 0;

			p {
				margin-bottom: 0;
			}
		}
	}

	.set-product-manufacturer-name {
		font-weight: bold;
		background-color: $white;

		span {
			font-weight: normal;
		}

		@include media-breakpoint-down(xs) {
			padding-bottom: 1rem;
		}
	}

	.set-product-sale-price {
		color: var(--color-red);
		font-size: 2rem;
		font-weight: bold;
	}

	.set-product-tax-info {
		font-size: 0.8rem;
		display: flex;
		flex-direction: row;
		gap: 0.3rem;

		@include media-breakpoint-down(xs) {
			padding-bottom: 1rem;
		}
	}

	.set-product-quantity-section {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;

		@include media-breakpoint-down(xs) {
			width: 100%;
			justify-content: space-between;
		}
	}

	.set-product-quantity-select {
		padding: 0 0.5rem;
	}

	.set-product-quantity-button {
		font-size: 0.8rem;
		border: none;
		background-color: $grey65;
		color: $white;
		padding: 0 0.5rem;
	}

	.set-wishlist {
		border: 1px solid $grey4;
		text-decoration: none;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0.4rem;
	}

	.set-main-post-listing {
		display: flex;
		flex-direction: column;
		gap: 2rem;
		align-items: center;

		@include media-breakpoint-down(xs) {
			align-items: normal;
			padding: 0 1.2rem;
		}
	}

	.set-main-articles-price {
		display: flex;
		flex-direction: row;
		gap: 4rem;

		@include media-breakpoint-down(xs) {
			flex-direction: column;
			gap: 0.8rem;
		}
	}

	.set-main-products-price {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-down(xs) {
			align-items: normal;
			justify-content: normal;
		}
	}

	.set-main-price-label {
		background-color: var(--color-red);
		padding: 0.5rem 0.5rem;
		color: $white;
	}

	.set-main-price-tag {
		color: $grey4;
		padding: 0.5rem 0.5rem;
		font-size: 0.8rem;
	}

	.set-main-price-strikethrough {
		text-decoration: line-through;
	}

	.set-items-description {

		@include media-breakpoint-down(xs) {
			display: flex;
			flex-direction: column;
		}
	}

	.add-to-cart-global {
		font-size: 0.8rem;
		border: none;
		background-color: $grey65;
		color: $white;
		padding: 1rem 1rem;
	}

	.set-services-title {
		font-size: 1.5rem;
	}

	.scroll-top-button-div {
		width: 100%;
		display: flex;
		flex-direction: row-reverse;
	}

	.set-scroll-top-button {
		background-color: $grey65;
		color: white;
		border: 1px solid $grey65;
		border-radius: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 1.4rem;
		height: 1.4rem;
		font-weight: 500;
	}

	.set-top-button {
		transform: rotate(-90deg);
	}

	.set-scroll-top {
		display: flex;
		flex-direction: row;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		background-color: inherit;
		border: none;
	}

	.set-scroll-top-text {
		padding-top: 1rem;
	}

	.set-angle-right {
		display: flex;
		justify-content: center;
		align-items: center;
		color: $grey65;
		width: 1rem;
		height: 1.4rem;
		transform: rotate(-90deg);
		font-weight: 500;
	}
}
