@import "../utilities/mediaqueries";
@import "productDiscount";
@import "productTileGrid";
@import "quickView";

$tileImageHeightXS: calc(100vw / 2);
$tileImageHeightMD: 12.5rem; //200px
$tileImageHeight: 15.9375rem; //255px

.product-tile {
	@include media-breakpoint-only(md) {
		min-height: 0;
	}

	@include media-breakpoint-down(md) {
		margin-bottom: 1.25rem; //20px
	}

	.stoerer {
		top: auto;
		bottom: 1rem;
	}

	.tile-body {
		padding: 0.625em 0 1em;

		@include media-breakpoint-down(xs) {
			padding-right: 0.9375rem;
			padding-left: 0.9375rem;
		}

		@include media-breakpoint-down(md) {
			height: 8.75rem; //140px
			position: relative;
			padding-bottom: 0;
		}

		.pdp-link {
			margin-bottom: 0.625rem; //10px

			@include media-breakpoint-up(md) {
				height: 2.375rem; //38px
			}

			span {
				font-weight: 400;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 100%;
				display: block;

				&.brandName {
					font-weight: 500;
					text-transform: capitalize;
					padding-bottom: 0.125rem;

					@include media-breakpoint-down(md) {
						margin-bottom: 0.3125rem;
					}
				}

				&.productName {
					@include media-breakpoint-down(md) {
						white-space: normal;
						height: 1.875rem;
					}

					@include media-breakpoint-only(md) {
						height: 2.1875rem; //35px
					}
				}
			}
		}

		.fa-inverse {
			color: $grey2;
		}

		.fa-expand {
			color: $grey4;
		}

		.quickview {
			display: flex;
			justify-content: flex-end;
			border-bottom: none;
		}

		.price {
			@include media-breakpoint-down(md) {
				position: absolute;
				bottom: 0;
				white-space: nowrap;
			}
		}
	}

	.tile-footer {
		display: flex;
		justify-content: flex-end;

		@include media-breakpoint-down(md) {
			bottom: 0;
			position: absolute;
			right: 0;
		}

		.vviinn-vpr-button::part(vviinn-button) {
			padding: 1px;
			margin-top: 2px;
			width: 36px;
			height: 36px;
		}
	}

	.image-container {
		height: $tileImageHeightXS;
		max-height: $tileImageHeightXS;
		overflow: initial; /* switched from hidden to initial because outline wasn't visible on focus-visible state */

		@include media-breakpoint-up(md) {
			height: $tileImageHeightMD;
			max-height: $tileImageHeightMD;
		}

		@include media-breakpoint-up(lg) {
			height: $tileImageHeight;
			max-height: $tileImageHeight;
		}

		.productLink {
			display: flex;
			justify-content: center;
			align-items: center;
			height: 100%;
			border-bottom: none;

			.tile-image {
				width: auto;
				max-width: 100%;
				max-height: $tileImageHeightXS;

				@include media-breakpoint-up(md) {
					max-height: $tileImageHeightMD;
				}

				@include media-breakpoint-up(lg) {
					max-height: $tileImageHeight;
				}
			}
		}
	}
}

.prices {
	text-align: left;
}

.promo-tile__wrapper {
    @include respond-to(small-phones) {
        &:nth-child(odd) + .product-tile-container {
            .product-tile-container-border {
                display: block;
                right: auto;
                left: -1px;
            }
        }
    }
}

.promo-tile {
    padding: 10px;
}
