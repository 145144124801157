.minicart {
	.minicart-icon {
		position: absolute;
		right: 10px;

		&::before {
			color: $grey4;
			font-size: 1.1875rem; // 19px
		}

		@include media-breakpoint-up(lg) {
			position: static;
		}
	}

	.minicart-quantity {
		background-color: $secondary;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 1.0625rem;
		height: 1.0625rem;

		@include media-breakpoint-down(md) {
			left: 0;
		}
	}

	.line-item-pricing-info {
		@include media-breakpoint-up(lg) {
			white-space: nowrap;
		}
	}

	.line-item-total-price {
		text-align: right;

        .strike-through {
            margin-right: 0;
        }
	}
}
