@import "productDiscount";

.badgesWrapper {
	position: absolute;
	top: 0;
}

.badgesWrapperTiles {
	position: absolute;
	left: 0;
	bottom: 0;

	@include media-breakpoint-up(md) {
		bottom: 1rem;
	}
}

.stoererTiles {
	@include productDiscountTiles();
}

.stoerer {
	@include productDiscount();

	position: relative;
	display: block;

	&.set-badge {
		padding: 0.25em 3em;
	}
}

.stoerer:not(:first-child) {
	margin-top: 0.5rem;
}

.badgesWrapperAbovePrice {
	top: 0;
}

.stoererAbovePrice {
	@include productDiscount();

	position: unset;
	width: fit-content;
	margin-bottom: 10px;
	display: inherit;
}

.product-wrapper {
	.productAndBrand {
		color: $grey65;
		display: flex;
		flex-direction: column-reverse;

		.product-brand {
			font-size: 1rem;
			font-weight: 700;
			order: 3;
			text-transform: uppercase;
		}

		.product-name {
			font-size: 1.5rem;
			order: 2;
		}

		.product-sku {
			color: $grey5;
			font-size: 0.875rem;
			order: 1;
		}
	}

	.availability-msg {
		font-size: 0.875rem;
		font-weight: 500;
		margin-bottom: 0;
	}

	.product-shipping-method,
	.product-item-quantity {
		display: flex;
		align-items: center;
		line-height: 1.1;

		@include media-breakpoint-down(xs) {
			font-size: 0.9rem;
		}
	}
	.product-shipping-method-wrap {
		margin-bottom: 0.5rem;
	}

	.product-shipping-method {

		&.m-delivery {
			align-items: flex-start;

			.product-shipping-method-icon {
				margin-bottom: 2px;
				background-position: -54px -10px;
			}
		}

		&.m-clickandcollect {

			.product-shipping-method-icon {
				background-position: -10px -10px;
				height: 35px;
			}
		}
	}

	.product-cc-store-list {
		padding-left: 1em;
	}

	.product-shipping-method-icon {
		display: block;
		flex: 0 0 32px;
		height: 26px;
		margin-right: 5px;
		background-image: url('../images/shipping-sprite.png');
		background-repeat: no-repeat;

		&.m-green-circle {
			margin: 0 5px 0 20px;
		}
	}

	.green-circle {
		display: block;
		flex: 0 0 auto;
		align-self: center;
		background-color: var(--color-green);
		border-radius: 100%;
		width: 12px;
		height: 12px;

		@include media-breakpoint-down(xs) {
			width: 10px;
			height: 10px;
		}
	}

	.product-delivery-methods-label {
		font-size: 14px;
		font-weight: 700;
	}

	.product-delivery-methods-selectbox {
		font-size: 12px;
		font-weight: 500;
		position: relative;
		line-height: 22px;
		padding: 7px 35px 7px 12px;
		margin-top: 4px;
		height: auto;
		width: 100%;		
	}

	.product-shipping-method-text {
		&.m-unavailable {
			color: var(--color-danger)
		}
	}

	.imageAndAttributesContainer {
		.sales {
			font-size: 1.875rem;
		}

		.strike-through {
			font-size: 0.875rem;
		}
	}

	.priceIncludesMsg {
		font-size: 0.75rem;
		display: block;
		margin-bottom: 1rem;

		@include media-breakpoint-down("md") {
			margin-top: 0.625rem; //10px
		}
	}

	.simple-quantity {
		width: 4.1875rem; // 67px
		margin-top: 0;
	}

	.cart-and-ipay {
		margin: 0 0 0 0.625rem;
		width: 100%;

		@include media-breakpoint-only(xs) {
			width: 13.125rem;
		}

		.btn {
			margin: 0;
			height: 100%;
			width: 100%;

			@include media-breakpoint-up(md) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}

		@include media-breakpoint-down(md) {
			flex: 1 0 auto;
			padding-bottom: 0;

			.btn {
				width: 100%;
			}
		}
	}

	.addToCartAndWishlist {
		display: flex;
		margin-bottom: 0.5rem;

		.quantity-select {
			height: 2.8125rem; //45px
		}
	}

	.shippingTime {
		font-size: 0.875rem;
		font-weight: 500;
		color: #424242;
		border-bottom: none;
		padding-bottom: 0.5rem;
	}

	.appointment {
		.btn {
			width: 100%;
		}
	}

	.availability-wrapper {
        margin: 0.5em 0;
    }

	.order-by-email {
		margin-left: 0;
	}
}

.attribute {
	margin-bottom: 20px;

	.color.non-input-label,
	.size {
			font-size: 0.875rem;
			text-transform: capitalize;
	}
}
