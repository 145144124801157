/* roboto-regular - latin */

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
	src:
		local('Roboto'),
		local('Roboto-Regular'),
		url('../fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-500 - latin */

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	src: url('../fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
	src:
		local('Roboto Medium'),
		local('Roboto-Medium'),
		url('../fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
		url('../fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin */

@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local('Roboto Bold'),
		local('Roboto-Bold'),
		url('../fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
		url('../fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-regular - latin */

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 400;
	src: url('../fonts/roboto-condensed-v19-latin-regular.eot'); /* IE9 Compat Modes */
	src:
		local('Roboto Condensed'),
		local('RobotoCondensed-Regular'),
		url('../fonts/roboto-condensed-v19-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/roboto-condensed-v19-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/roboto-condensed-v19-latin-regular.woff') format('woff'), /* Modern Browsers */
		url('../fonts/roboto-condensed-v19-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/roboto-condensed-v19-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}

/* roboto-condensed-700 - latin */

@font-face {
	font-family: 'Roboto Condensed';
	font-style: normal;
	font-weight: 700;
	src: url('../fonts/roboto-condensed-v19-latin-700.eot'); /* IE9 Compat Modes */
	src:
		local('Roboto Condensed Bold'),
		local('RobotoCondensed-Bold'),
		url('../fonts/roboto-condensed-v19-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		url('../fonts/roboto-condensed-v19-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
		url('../fonts/roboto-condensed-v19-latin-700.woff') format('woff'), /* Modern Browsers */
		url('../fonts/roboto-condensed-v19-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
		url('../fonts/roboto-condensed-v19-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
}
