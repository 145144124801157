.site-search {
	@include media-breakpoint-down(lg) {
		width: 100%;
	}

	input {
		border-radius: 3px;
		border-color: $grey3;
	}

	form {
		height: 100%;
	}

	.fa-search::before {
		color: $grey4;
		font-size: 1.125rem; // 18px
	}

	.reset-button {
		right: 4.375em; /* 70/16 */
		top: 0.75em; /* 12/16 */
	}

	.fa-close,
  .fa-search {
		top: 50%;
		transform: translateY(-50%);
	}
}

.vviinn-vps-button {
	align-items: center;
	display: flex;
	height: 22px;
	line-height: 22px;
	position: absolute;
  top: 9px;
  right: 35px;

	svg {
		transform: scale(0.85);
	}
}
