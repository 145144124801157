@import "../../../../../../../../sfra_framework/node_modules/bootstrap/scss/mixins/breakpoints";
@import "~schaffrath/variables";

.headerMenu {
	.dropdown-flyout {
		&.show {
			display: flex;
		}
	}
}
