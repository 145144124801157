/// Mixin for applying styles to Page Designer edit view components.
///
/// This mixin targets elements with specific data attributes used in Salesforce Commerce Cloud's
/// Page Designer edit view. It allows you to encapsulate styles that should only be applied
/// within the context of Page Designer's editing interface.
///
/// @param {String} $class - The class name to scope the styles within the Page Designer edit view.
///
/// @content - The block of styles to be applied to the targeted elements.

@mixin page-designer-edit-view($class) {
    .#{$class} {
        &[data-sfcc-pd-region-info],
        &[data-sfcc-pd-component-info] {
            @content;
        }
    }
}
