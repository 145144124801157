$page-designer-gutters: (
	sm: (
		'sm': 12px,
		'md': 12px,
		'lg-up': 12px
	),
	md: (
		'sm': 40px,
		'md': 52px,
		'lg-up': 52px
	),
	lg: (
		'sm': 16px,
		'md': 44px,
		'lg-up': 64px
	),
	xl: (
		'sm': 48px,
		'md': 44px,
		'lg-up': 80px
	)
);
