@mixin productDiscount() {
	position: absolute;
	left: 0;
	top: 1.875rem;
	background-color: $secondary;
	color: $white;
	padding: 0.25em 0.5em;
	font-size: 0.6875em;
	font-weight: 500;

	@include media-breakpoint-up(md) {
		top: 3.5rem;
		font-size: 0.75em;
	}
}

@mixin productDiscountTiles() {
	display: block;
	background-color: $secondary;
	color: $white;
	padding: 0.15em 0.55em;
	font-size: 0.5875em;
	font-weight: 500;
	margin-top: 0.25rem;
	width: fit-content;
	max-width: 6rem;

	@include media-breakpoint-up(md) {
		max-width: unset;
		top: 0;
		font-size: 0.75em;
	}
}
