@import "../variables";

.carousel-preview {
	padding-left: 2rem;

	.carousel-preview-item {
		margin: 1rem 1rem 1rem 0;
		padding: 0 0 0 0;
		height: 5rem;
		width: 5rem;
		border-color: $border-color;

		.carousel-preview-image {
			background-size: cover !important;
			background-position: center !important;
			height: 100%;
			width: 100%;
			border-style: solid;
			border-width: thin;
			border-color: inherit;
		}
	}

	.active {
		border-color: $secondary !important;
	}
}
