$product-safety-pdf-link-font: 1rem / 1 "Roboto Condensed";
$product-safety-pdf-link-font--large: 1.25rem / 1 "Roboto Condensed";

.product-information-modal {
    .modal-content {
        position: relative;
    }

    .close {
        position: absolute;
        top: 16px;
        right: 12px;
    }
}

.product-information-btn {
    padding: 0.5rem 0 1.5rem;
}

.product-information-modal__text {
    & + .product-information-modal__links-label {
        border-top: 1px solid $grey3;
        margin-bottom: 1rem;
        padding-top: 1rem;
    }
}

.product-information-modal__label {
    font-size: 1.25rem;
}

.product-manufacturer__text {
    & > * {
        display: block;
        margin-bottom: 1rem;
    }
}

.product-information-modal__links-label,
.product-info-pdf__link {
    font: $product-safety-pdf-link-font;

    @include media-breakpoint-up(md) {
        font: $product-safety-pdf-link-font--large;
    }
}

.product-info-pdf__list {
    padding-left: 15px;
}

.product-info-pdf {
    margin: 0 0 5px;

    &::marker {
        color: $blue;
    }
}

.product-info-pdf__link {
    color: $blue;
    border: none;

    &:hover {
        color: $blue;
        border: none;
    }
}
