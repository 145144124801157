@import "menu";

#pageHeader {
    .header-content {
        background-color: $white;
        position: relative;
        transition: .6s transform ease-in-out;
        transform-origin: top center;

        @include media-breakpoint-up(lg) {
            box-shadow: 0 4px 10px -1px rgba(0,0,0,.1);
            margin-bottom: 30px;
        }
    }

    &.sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1049;
        transform: translate(0px, 0px);

        & + div {
            margin-top: 30px;
        }

        .header-content {
            @include media-breakpoint-up(lg) {
                margin-bottom: 0;
            }
        }

        #removeProductModal {
            position: absolute;
            top: 0;
            left: -100%;
        }
    }

    &.scroll-start {
        pointer-events: none;

        .header-content {
            transform: translateY(-100%);
        }
    }

    &.scroll-up {
        pointer-events: all;

        .header-content {
            transform: translateY(0);
        }
    }

	hr {
		margin: 0;
		border-color: $grey25;
	}

	.navbar-header {
		display: flex !important;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(md) {
			position: relative;
		}

		.search,
		.user,
		.wishlist,
		.minicart {
			margin: 0;
		}

		.search {
			width: 60%;
		}

		.fa-user::before,
		.fa-heart::before {
			color: $grey4;
			position: relative;
		}

		.fa-user::before {
			font-size: 1.5625rem; // 25px
			bottom: -1px;
		}

		.fa-heart::before {
			font-size: 1.5rem; // 24px
			bottom: -3px;
			margin-left: 1.5rem;
		}
	}

	.navbar-nav {
		.nav-link:not(.meta-navigation),
		.dropdown-link {
			text-transform: uppercase;
			font-size: 0.875rem; //14px
			font-family: "Roboto Condensed", sans-serif;
		}

		@include media-breakpoint-up(xl) {
			.nav-link {
				padding: 0.91rem;
			}
		}
	}

    .meta-navigation {
        a {
            border-bottom: none;
        }
    }

	.meta-navigation {

		@include media-breakpoint-up(lg) {
			display: block;
			min-height: 1.875rem;
			font-size: 0.75rem;

			ul {
				display: flex;
				justify-content: center;
				padding-left: 0;
				margin-bottom: 0;
				list-style: none;

				a {
					padding: 0.5rem;
					text-decoration: none;
					color: $grey4;
					display: block;

					&:hover,
					&:focus {
						color: rgba(0, 0, 0, 0.7);
					}
				}
			}
		}

        .experience-component:not(.experience-assets-promoBanner) {
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }
        }
    }

	.brand {
		padding: 0;
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: center;
			left: auto;
			width: 100%;
		}

		@include media-breakpoint-up(lg) {
			width: 20%; /* 180/16 */
			margin: 0;
		}
	}

	.logo-home {
		@include media-breakpoint-down(md) {
			width: 9.125rem; /* 146/16 */
		}

		border-bottom: none;

		@include media-breakpoint-down(md) {
			img {
				width: 140% !important;
			}
		}
	}

	.action-navi {
		display: flex;

		.user a,
		.wishlist a,
		.minicart-link,
		.location-icon-link {
			border-bottom: none;
		}

		.location-icon-link {
			font-size: 1.6rem;
			margin-top: 3px;
			margin-left: 10px;
			color: $grey4;

			@include media-breakpoint-down(md) {
				display: none;
			}
		}
	}

	.navbar-toggler {
		padding: 0;
		color: $grey4;
		z-index: 1;
	}

	.close-button {
		color: $grey4;

		button {
			display: flex;
			align-items: center;
		}

		span {
			line-height: 0;
			margin-left: 0.3125em; //5px
			font-size: 1.375rem; //22px
		}
	}

	.user .popover {
		.nav {
			display: block;
		}

		a {
			&:hover,
			&:focus {
				color: rgba(0, 0, 0, 0.7);
			}
		}
	}

    .pre-header.no-menu {
        .meta-navigation {
            min-height: unset;

            .experience-component:not(.experience-assets-promoBanner) {
                display: none;
            }
        }
    }
}

.container {
	&.pre-header {
		width: 100%;
		max-width: 100%;
		padding-left: 0;
		padding-right: 0;

		.meta-navigation {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}
	}

	&.content {
		img {
			width: 100%;
		}
	}

	.content-asset {
		img {
			width: 100%;
		}
	}
}
