.pd-promo-banner {
    position: relative;

    &.is-sticky {
        position:fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1005;
    }

    .row {
        margin: 0;
    }

    .carousel-item {
        padding: 0;
    }
}

.experience-assets-promoBanner {
    display: none;

    &.show-banner {
        display: block;
    }

    @include media-breakpoint-up(lg) {
        margin: 0 0 0.75rem;
    }

    .region {
        margin: 0;
    }

    a {
        color: inherit;
        text-decoration: underline;
    }

    .carousel {
        @include g-page-designer-component-paddings('.styleTextBox-component-container');

        .pd-promo-banner__carousel-control {
            display: none;
            height: 100%;
            width: 20px;
        }

        .carousel-control-prev {
            left: 20px;
            padding-left: 5px;

            @include media-breakpoint-up(lg) {
                left: 30px;
            }
        }

        .carousel-control-next {
            right: 20px;
            padding-right: 5px;

            @include media-breakpoint-up(lg) {
                right: 30px;
            }
        }

        @include media-breakpoint-only(xs) {
            &.controls-xs {
                .pd-promo-banner__carousel-control {
                    display: flex;
                }
            }
        }

        @include media-breakpoint-only(sm) {
            &.controls-sm {
                .pd-promo-banner__carousel-control {
                    display: flex;
                    top: 0;
                }
            }
        }

        @include media-breakpoint-up(md) {
            &.controls-md {
                .pd-promo-banner__carousel-control {
                    display: flex;
                }
            }
        }
    }

    .styleTextBox-component-container {
        p {
            margin-bottom: 0 !important; // overrides initial component's style !important
        }
    }

    .pd-promo-banner__close-button {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 22px;
        text-shadow: none;
        border: none;
        background: transparent;
        font-size: 20px;

        @include media-breakpoint-up(lg) {
            right: 5px;
            font-size: 25px;
        }
    }
}

@include page-designer-edit-view(experience-assets-promoBanner) {
    display: block;

    .pd-promo-banner {
        position: relative;
    }

    .carousel {
        .row {
            margin-right: 0;
            margin-left: 0;

            .col,
            [class*="col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }

        @include media-breakpoint-only(xs) {
            &[data-xs="1"] {
                .carousel-item {
                    margin-right: -100%;
                }
            }
        }

        @include media-breakpoint-between(sm, md) {
            &[data-sm="1"] {
                .carousel-item {
                    margin-right: -100%;
                }
            }
        }

        @include media-breakpoint-up(lg) {
            &[data-md="1"] {
                .carousel-item {
                    margin-right: -100%;
                }
            }
        }

        .carousel-control-prev-icon,
        .carousel-control-next-icon {
            @include media-breakpoint-down(sm) {
                height: 0.9375rem;
                width: 0.9375rem;
            }
        }
    }
}
