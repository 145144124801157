@import "../utilities/swatch";
@import "productCommon";
@import "../product/detail";

.quick-view-dialog {
  max-width: 56.25em;

	.modal-header {
		border: 0;
		padding: 0;
	}

	button.close {
		font-size: 1.5rem;
		position: absolute;
		right: 32px;
		top: 32px;
		z-index: 2;
	}

	.modal-content {
		padding: 32px 0;
	}

	.modal-body {
		max-height: none;
	}

	.product-wrapper .cart-and-ipay {
		margin-right: 0;
		width: 100%;
	}

	.product-name {
		padding-bottom: 1rem;
		border-bottom: 1px solid $grey3;
		margin-bottom: 1rem;
	}

	.btn-primary {
		width: 100%;
	}

	.attributes {
			dt,
			dd {
					display: inline-block;
					font-size: 0.875rem;
					margin-bottom: 0;
					text-transform: capitalize;
			}

			dt {
					font-weight: 700;
			}

			@include media-breakpoint-up(md) {
					padding-left: 0;
					padding-right: 0;
			}
    }

    .product-detail [disabled] .swatch-circle,
    .product-detail .swatch-circle.m-disabled, {
        position: relative;

        &.color-value.selected::after {
            background-color: $gray-700;
        }
    }
}
