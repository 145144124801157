@charset "UTF-8";

@import "utilities/mediaqueries";
@import "utilities/toRem";
@import "variables";
@import "baseGlobal";
@import "skin/skinVariables";
@import "bootstrapOverrides";
@import "breakviews";
@import "components/fonts";
@import "components/footer";
@import "~pageDesigner/components/footerMenu";
@import "components/hero";
@import "components/header";
@import "~pageDesigner/components/headerMenu";
@import "components/pricing";
@import "components/productTiles";
@import "components/productCommon";
@import "components/breadcrumb";
@import "components/wishlistGlobal";
@import "miniCart";
@import "components/headerSearch";
@import "components/productDiscount";
@import "components/contact-us-appointment";
@import "~pageDesignerExt/00-configuration/all_pd";
@import "~pageDesignerExt/01-core/all_pd";
@import "~pageDesignerExt/02-components/g-promo-banner";

#maincontent {
	padding-bottom: 1.875rem;
}

*:focus {
	outline: 0 !important;
}

*:focus-visible {
	outline: $primary auto 1px !important;
}

.suggestions-wrapper {
	.suggestions {
		.items {
			.item {
				a {
					border-bottom: 0;
				}
			}
		}
	}
}

.heading1 {
	display: block;
	font-size: 2rem;
	margin-block-start: 0.67em;
	margin-block-end: 0.67;
	margin-inline-start: 0;
	margin-inline-end: 0;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 0.5rem;
	line-height: 2.625rem;
}

.styleTextBox-component-container,
.experience-commerce_assets-editorialRichText {
	p {
		margin-bottom: 1rem !important;

		br {
			display: none !important;
		}
	}
}

#ldnzr-funnel-widgetContainer {
    display: none;
}

.leadanizer-on {
    #ldnzr-funnel-widgetContainer.show {
        display: flex;

        @media screen and (min-width: 601px) {
            display: block;
        }
    }
}
