@import "../variables";

$set-unit-price-font: .8rem / 1 $font-family-sans-serif;

.price {
	color: $grey65;

	.strike-through {
		font-size: 0.75rem;
		text-decoration: none;
		color: $grey65;

		@include media-breakpoint-up(lg) {
			font-size: 0.875rem;
		}
	}

	.sales {
		margin-right: 5px;

		@include media-breakpoint-down("md") {
			sup {
				top: 3px;
			}
		}

		@include media-breakpoint-down("md") {
			display: flex;
			flex-direction: column;
		}

		@include media-breakpoint-up("lg") {
			sup.pdpText {
				vertical-align: text-top;
			}
		}
	}

	.showOriginalPriceOnly {
		color: $grey65;
	}

	.range {
		color: $grey65;

		.salePriceSpan {
			align-items: center;
			flex-direction: row;

			.salePriceSpan {
				margin-left: 5px;
			}
		}
	}
}

.set-unit-price {
    font: $set-unit-price-font;
    color: $grey5;
    display: block;
}

.set-unit-price--pdp {
    margin: 5px 0;
}

.metaWerbeaktionCase {
	color: $secondary !important;
}

.textAndPrice {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
}

.uvpText {
	font-size: 0.75rem !important;
	font-weight: bold;
	color: #222;
	margin-right: 10px;
}

.uvpAlign {
	display: flex;
	justify-content: center;
}

.salePriceSpan {
	display: flex;
	flex-direction: column;
}

.savingsText {
	font-size: 0.75rem;
	color: $grey65;
	font-weight: 500;
}

.wishlistItemCards {
	.range .salePriceSpan {
		flex-direction: column;
	}
}
