
@import "../variables";
@import "~base/product/detail";
@import "../components/productDiscount";
@import "carouselPreviewImages";
@import "./setDetail";
@import "../utilities/swatch";
@import "../product/product-information-modal";


.imageAndAttributesContainer {
	--maxHeightImage: 48.4375; //775px
	--maxHeightImageXS: 23.4375rem; //375px

	@include media-breakpoint-only(xs) {
		.imageCarouselOuterGrid {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.carousel-item {
		height: 23.4375rem; //375px

		@include media-breakpoint-up(lg) {
			height: 30.625rem; //490px
		}

		@include media-breakpoint-up(xl) {
			height: 48.4375rem; //775px
		}
	}

	.modal .carousel-item {
		@include media-breakpoint-up(xs) {
			height: auto;
		}
	}

	.carousel-item.active,
	.carousel-item-next,
	.carousel-item-prev {
		display: flex;
		justify-content: center;
	}

	.carousel-preview {
		padding-left: 2rem;

		.carousel-preview-item {
			margin: 1rem 1rem 1rem 0;
			padding: 0 0 0 0;
			height: 5rem;
			width: 5rem;
			border-color: $border-color;

			.carousel-preview-image {
				background-size: cover !important;
				background-position: center !important;
				height: 100%;
				width: 100%;
				border-style: solid;
				border-width: thin;
				border-color: inherit;
			}
		}

		.active {
			border-color: $secondary !important;
		}
	}
}

.primary-images {
	@include media-breakpoint-down(xs) {
		margin-bottom: 1.25rem;
	}
}

.img-resize {
	object-fit: contain;
}

.product-breadcrumb .breadcrumb {
	@include media-breakpoint-down(md) {
		border-top: none;
	}
}

.attributesSpacing {
	margin-top: 2rem;
}

.attributes {
	dt,
	dd {
		display: inline-block;
		font-size: 0.875rem;
		margin-bottom: 0;
		text-transform: capitalize;
	}

	dt {
		font-weight: 700;
	}

	@include media-breakpoint-up(md) {
		padding-left: 0;
		padding-right: 0;
	}
}

.add-to-wish-list {
	order: 3;
	padding: 0;
	width: fit-content;
	align-self: flex-end;

	&:hover {
		opacity: 0.8;
	}

	.fa-heart {
		color: $grey4;
		font-size: 1.25rem;
	}
}

.description-and-detail,
.recently-viewed,
.recommendations {
	.content {
		margin-top: 0.4rem;
	}

	.title {
		font-size: 1.5rem;
	}

	@include media-breakpoint-only(xs) {
		.title {
			font-size: 1.125rem;
			font-family: inherit;
			color: $body-color !important;
			padding-top: 0.875rem;
			padding-bottom: 0.875rem;
			line-height: inherit;
		}
	}
}

.pdp-similar-products {
	@include media-breakpoint-down(sm) {
		margin-top: 15px;
	}
}

.description-and-detail {
	.product-brand,
	.product-name {
		font-size: 1.25rem;
		line-height: 1.625rem;
	}

	.product-brand {
		font-weight: 700;
		text-transform: uppercase;
	}

	table.attributes td:first-child {
		font-weight: bold;
		padding-right: 2rem;
	}

	.documents-list {
		padding-left: 0;

		li {
			list-style: none;
			margin-bottom: 0.4rem;
		}
	}

	@include media-breakpoint-up(md) {
		.row {
			margin-bottom: 2rem;
		}
	}

	@include media-breakpoint-only(xs) {
		.collapsible-xs {
			.button-container {
				padding-left: 0;
				padding-right: 0;

				button {
					padding-left: 15px;
					padding-right: 15px;
				}
			}
		}
	}
}

@include media-breakpoint-only(xs) {
	.description-and-detail .row,
	.imageAndAttributesContainer {
		border-bottom: 1px solid $grey3;
	}
}

.social-icons {
	a {
		border-bottom: none;
	}
}

.store-info-title {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: 12px;
}

.store-info {
	font-size: 12px;
	margin-bottom: 2px;
}

@-webkit-keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@keyframes spinner-border {
	to {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.spinner-border {
	display: inline-block;
	width: 1rem;
	height: 1rem;
	vertical-align: text-bottom;
	border: 0.1em solid currentColor;
	border-right-color: transparent;
	border-radius: 50%;
	-webkit-animation: 0.75s linear infinite spinner-border;
	animation: 0.75s linear infinite spinner-border;
}

#checkStoreAvailabilityModal {
	.modal-dialog {
		@include media-breakpoint-down(md) {
			&.modal-lg {
				max-width: 90%;
			}
		}
	}
}

.product-detail {
	.availability {
		margin-top: 0;
	}

	.availability-icon {
		width: 20px;
	}

	.product-availability-wrapper {
		margin-top: 20px;

		.product-shipping-method-wrap {
			margin-top: 5px;
			font-size: 0.875rem;

			.product-shipping-method.m-delivery {
				align-items: center;
			}
		}
	}

	.btn-check-store-availability-wrapper,
	.btn-appointment-wrapper {
		margin-bottom: 5px;
		display: flex;

		button {
			text-align: left;
		}
	}

	.btn-appointment,
	.btn-check-store-availability {
		border: none;
		background-color: transparent;
		font-size: 0.875rem;
		text-decoration: underline;
		color: $grey65;

		&:hover {
			opacity: 0.8;
		}
	}

	.hide-on-mobile {
		@include media-breakpoint-only(xs) {
			display: none;
		}
	}

	.hide-md-up {
		@include media-breakpoint-up(sm) {
			display: none;
		}
	}

	.product-availability-wrapper {
		margin-top: 20px;

		.product-shipping-method-wrap {
			margin-top: 5px;
			font-size: 0.875rem;

			.product-shipping-method.m-delivery {
				align-items: center;
			}
		}
	}

	.btn-check-store-availability-wrapper,
	.btn-appointment-wrapper {
		margin-bottom: 5px;
		display: flex;

		button {
			text-align: left;
		}
	}

	.btn-appointment,
	.btn-check-store-availability,
    .product-information-btn {
		border: none;
		background-color: transparent;
		font-size: 0.875rem;
		text-decoration: underline;
		color: $grey65;

		&:hover {
			opacity: 0.8;
		}
	}

	.hide-on-mobile {
		@include media-breakpoint-only(xs) {
			display: none;
		}
	}

	.hide-md-up {
		@include media-breakpoint-up(sm) {
			display: none;
		}
	}

	.videos {
		.product-video {
			display: none;
			width: 100% !important;
		}

		&.collapsible-xs {
			.title {
				&:after {
					content: "\f078";
					display: inline-block;
					font-family: "FontAwesome";
					margin-left: 10px;
					font-size: 10px;
					position: relative;
					top: -4px;

					@include media-breakpoint-down(xs) {
						font-size: 1.125rem;
						top: -1px;
					}
				}
			}

			&.active {
				.product-video {
					display: block;
				}

				.title {
					&:after {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	.description-and-detail {
		.product-attributes,
		.pdp-similar-products,
		.videos,
		.services {
			&.row {
				@include media-breakpoint-up(sm) {
					display: block;
				}
			}
		}

		.services {
			@include media-breakpoint-down(sm) {
				border-top: 1px solid #ccc;
			}
		}

		~ .recommendations {
			.recommendations > .row,
			&.row {
				@include media-breakpoint-up(sm) {
					display: block;
				}
			}
		}
	}

	.product-content {
		margin-bottom: 10px;

		&-heading {
			font-size: 1.5rem;
			cursor: pointer;
			font-weight: 500;
			font-family: var(--skin-header-font), sans-serif;

			@include media-breakpoint-down(xs) {
				font-size: 1.125rem;
			}

			&:after {
				content: "\f078";
				display: inline-block;
				font-family: "FontAwesome";
				margin-left: 10px;
				font-size: 10px;
				position: relative;
				top: -4px;
			}
		}

		> p,
		> table {
			display: none;
		}

		input[type="checkbox"] {
			display: none;

			&:checked {
				~ p,
				~ table {
					display: block;
				}

				~ label {
					&:after {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

    .carousel-preview {
        max-width: 100%;
    }

    .addToCartAndWishlist {
        @include media-breakpoint-between(sm, md) {
            flex-wrap: wrap;
        }
    }

    .cart-and-ipay {
        @include media-breakpoint-between(sm, md) {
            order: 0;
            margin: 0 0 10px 0;
            .add-to-cart {
                height: auto;
            }
        }
    }

    .simple-quantity {
        @include media-breakpoint-between(sm, md) {
            order: 1;
        }
    }
}

.check-store-availability-modal {
	@include media-breakpoint-only(xs) {
		width: calc(100% - 30px);
	}

	.store-availability-subtitle {
		font-size: 1.2em;
		line-height: 1.2;
		margin-bottom: 2em;

		@include media-breakpoint-only(xs) {
			font-size: 1.1em;
			margin-bottom: 1em;
		}
	}

	.store-availability-row {
		margin-bottom: 2em;
	}

	.store-availability-message {
		align-self: flex-start;
		display: flex;

		@include media-breakpoint-up(sm) {
			justify-content: center;
		}
	}

	.store-availability-message-icon {
		margin-right: 5px;
	}

	.store-availability-link-wrap {
		display: flex;
		align-items: baseline;

		@include media-breakpoint-up(sm) {
			justify-content: center;
		}


		&::before {
			content: '';
			display: block;
			width: 15px;
			height: 8px;
			background: url('../../images/arrow-down.png') no-repeat;
			background-size: 15px;
			transform: rotate(-90deg);
		}
	}
}

.product-detail .attributes .swatch-circle {
	// $white will be replaced by color image background in ISML
	height: 34px;
	width: 34px;

	&.color-value.selected::after {
		font-size: 20px;
		width: 1rem;
		height: 1rem;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		box-shadow: inset 0 0 0 1px rgba(0,0,0,1);
	}
}

.product-detail .swatch-circle.m-disabled {
	position: relative;

	&.color-value.selected::before {
			background-color: $gray-700;
	}
}

.product-detail .swatch-circle.m-disabled {
	&::before {
		background-color: $grey4;
		border: 1px solid $white;
		border-radius: 50px;
		display: block;
		content: "";
		height: 3px;
		left: -3px;
		position: absolute;
		top: 15px;
		transform: rotate(45deg);
		width: 37px;
	}
}

.recommendations-compared {
	&-wrapper {
		display: flex;
		width: 100%;

		@include media-breakpoint-down(md) {
			flex-wrap: wrap;
		}

		.plus {
			font-size: 70px;
			align-self: center;
			text-align: center;
			padding: 0 5px;

			@include media-breakpoint-down(md) {
				width: 100%;
			}
		}
	}

    .vviinn-block {
        width: 100%;
    }

	.product-tile__wrapper {
		width: 25%;
		padding-top: 56px;

		@include media-breakpoint-down(md) {
			width: 100%;
		}
	}

	.product-tile {
		border: none;

		.image-container {
			height: 274.42px;
			max-height: initial;

			.tile-image {
				height: 100%;
				max-height: 100%;
			}
		}
	}
}

.vvinn-button {
	border-color: transparent;
	border: none;
	display: block;
	margin: 5px auto;
	transition: opacity 0.3s;

	&:hover {
		opacity: 0.8;
	}
}

.btn-appointment-fixed {
	position: relative;

	.btn-appointment-wrapper {
		position: fixed;
		z-index: 999;
		bottom: 50px;
		right: 20px;
		background-color: $secondary;	

		display: flex;
		align-items: center;
		flex-wrap: nowrap;

		.availability-icon {
			width: 20px;
			height: 20px;
		}

		.btn-appointment {
			border: none;
			background-color: transparent;
			color: $white;
		}
	}
} 
