body::before {
	content: 'xs';
	height: 0;
	width: 0;
	font-size: 0;
	line-height: 0;
	position: absolute;
}

@include media-breakpoint-only('sm') {
	body::before {
		content: 'sm';
	}
}

@include media-breakpoint-only('md') {
	body::before {
		content: 'md';
	}
}

@include media-breakpoint-only('lg') {
	body::before {
		content: 'lg';
	}
}

@include media-breakpoint-up('xl') {
	body::before {
		content: 'xl';
	}
}
