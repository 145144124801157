@import "~base/bootstrapOverrides";

.carousel {
	.carousel-control-prev,
	.carousel-control-next {
		border-bottom: none;
	}

	.icon-prev,
	.icon-next {
		background-color: rgba(195, 195, 195, 0.4);
		font-size: 2rem;
		font-weight: 700;

		&::before {
			color: $black;
		}
	}
}

.custom-radio,
.custom-checkbox {
	.custom-control-label::before {
		border-color: $grey4;
		background: $white;
	}
}

.custom-radio {
	.custom-control-input:checked ~ .custom-control-label::before {
		background: $grey65;
	}

	.custom-control-label::after {
		background: no-repeat 3px 4px / 7px;
	}
}

.custom-checkbox {
	.custom-control-input:checked ~ .custom-control-label::after {
		background-size: 0.625rem;
		background-color: $grey65;
	}
}

.nav-link,
.dropdown-link,
.nav-item a {
	border-bottom: none;
}

.form-group.is-invalid {
	& .invalid-feedback {
		display: block;
	}
}
