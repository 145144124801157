@import "../../../../../../../../sfra_framework/node_modules/bootstrap/scss/mixins/breakpoints";
@import "~schaffrath/variables";

.footerMenu {
	.twoCols ul {
		@include media-breakpoint-up(lg) {
			column-count: 2;
		}
	}
}
