.breadcrumb-item {
	font-size: 0.875rem;
	font-weight: 500;

	a {
		border-bottom: none;
	}

	+ .breadcrumb-item {
		&::before {
			padding-right: 0;
		}
	}
}
