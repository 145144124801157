footer {
	background-color: #ebe4de;

	.footer-menu-wrapper {
		@include media-breakpoint-up(md) {
			.col-lg-2 {
				flex: 0 0 25%;
				max-width: 25%;
			}

			.col-lg-4 {
				flex: 0 0 50%;
				max-width: 50%;
			}

			.certificate {
				flex: 0 0 100%;
				max-width: 100%;
				flex-basis: 100%;
			}
		}
	}
}

.footer-top {
	&-secure {
		align-items: center;
		display: flex;

		@include media-breakpoint-down(md) {
			margin-bottom: 16px;
		}

		svg {
			margin-right: 5px;
		}
	}

	&-content {
		align-items: center;
		display: flex;
		justify-content: space-between;

		@include media-breakpoint-down(sm) {
			align-items: flex-start;
			flex-direction: column-reverse;
		}
	}
}

.footer-bottom {
	li {
		display: inline-block;
		margin-right: 20px;

		&:last-child {
			margin-right: 0;
		}

		@include media-breakpoint-down(md) {
			margin-bottom: 16px;
		}
	}
}
.footerMenu {

	h3,
	button.title {
		font-family: 'Roboto Condensed', sans-serif;
		font-weight: 700;
		font-size: 1rem;
		text-transform: uppercase;
		color: $grey65 !important;
	}

	h4 {
		font-family: Roboto, sans-serif;
		font-weight: 700;
		font-size: 0.875rem; //14px
		color: $grey65;
	}

	a {
		border-bottom: none;
		color: inherit;
	}

	hr {
		border-bottom: 0.5px solid $hr-border-color-dark;
	}

	button.title {
		padding: 0;
		line-height: 2.5rem;
	}

	.collapsible-md {
		button.title {
			@include media-breakpoint-down(md) {
				border-bottom: 1px solid #979797 !important;
			}
		}

		&.active {
			button.title {
				border-bottom: 0 !important;
			}
		}
	}

	.social-links li {
		@include media-breakpoint-down(sm) {
			&:first-child {
				margin-left: 0;
			}

			width: 13%;
		}
	}

	.photo-tile-container .col-12 {
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.cert-figure {
			margin-right: 0.3125rem; //5px

			&:last-child {
				margin-right: 0;
			}
		}

		@include media-breakpoint-up(sm) {
			.cert-figure {
				margin-right: 1.25rem; //20px
			}
		}

		@include media-breakpoint-up(lg) {
			justify-content: space-between;

			.cert-figure {
				margin-right: 0;
			}
		}
	}

	.newsletter-container {
		background-color: #989087;
		padding-top: 2rem;
		padding-bottom: 0.5rem;

		.newsletter-description {
			color: $body-bg;
		}

		.emarsys-subscribe-email {
			background-color: $secondary;
		}

		.newsletter-privacy {
			padding-top: 0.4rem;
			font-size: 0.8rem;
			color: $body-bg;
		}

		.newsletter-form {
			overflow: hidden;

			&.error .newsletter-form-error {
				margin-bottom: 0;
				transition: margin-bottom 1s ease;
			}
		}

		.newsletter-form-error {
			width: 100%;
    		margin-top: 0.5rem;
			color: var(--color-danger);
			margin-bottom: -100%;
			transition: margin-bottom 2s ease;
		}
	}
}

/* Temporary part of the code which fixes recaptcha behavior on the latest version of IOS */
.grecaptcha-badge {
	@include media-breakpoint-down(lg) {
		&:hover{
			right: 0 !important;
		}
	}
}
